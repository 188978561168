<template>
    <div class="area">
        <h2 class="not-found">404 页面不存在</h2>
    </div>
</template>

<script>
    export default {
        name: "NotFound"
    }
</script>

<style scoped lang="less">
    .not-found{
        margin: 200px auto 0;
    }
</style>
