<template>
    <div class="area">
        <h2 class="not-found">403 您没有权限</h2>
    </div>
</template>

<script>
    export default {
        name: "Forbidden"
    }
</script>

<style scoped lang="less">
    .not-found{
        margin: 200px auto 0;
    }
</style>
